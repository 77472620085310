import React from "react"
import { Container, Divider, Grid, Typography } from "@mui/material"
import { graphql } from "gatsby"
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub"
import Layout from "../components/Layout/Layout"
import { Helmet } from "react-helmet"
const CassandraToolKit = () => {
  return (
    <Layout>
      <Helmet>
        <title> Cassandra Toolkit - Cassandra Link</title>
        <meta
          name="description"
          content="Explore the Cassandra Toolkit on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta
          property="og:title"
          content="Cassandra Link - Cassandra Toolkit"
        />
        <meta
          property="og:description"
          content="Explore the Cassandra Toolkit on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://cassandra.link/cassandra-toolkit"
        />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Cassandra Link - Cassandra Toolkit"
        />
        <meta
          name="twitter:description"
          content="Explore the Cassandra Toolkit on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Cassandra-Toolkit - Cassandra Link",
            keywords: "Cassandra Link, Cassandra-Toolkit,Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        {/* Open Graph */}
        <meta
          property="og:title"
          content={"Cassandra-Toolkit - Cassandra Link"}
        />

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={12}>
            <Grid>
              <Typography variant="h2" gutterBottom>
                Cassandra Toolkit
              </Typography>
              <Typography gutterBottom>
                View on{" "}
                <a href="https://github.com/Anant/cassandra.toolkit">
                  GitHub <AiFillGithub />
                </a>
              </Typography>
            </Grid>
            <Grid>
              <Typography gutterBottom>
                A hand-curated and tested list of tools that we recommend for
                building, managing, and monitoring Cassandra clusters.
              </Typography>
              <Typography gutterBottom>
                Find more tools from the open source community at{" "}
                <a href="https://www.cassandra.tools" target="_blank">
                  www.cassandra.tools
                </a>
                .
              </Typography>
              <Typography gutterBottom>
                Maintained by Rahul Singh of{" "}
                <a href="https://www.anant.us/home">Anant</a>. Feel free contact
                me if you'd like to collaborate on this and other tools. Please
                take a look!
              </Typography>

              <Typography variant="h3">Tools</Typography>
              <Divider sx={{ margin: "20px 0" }} />

              <ul>
                <li>
                  <Typography>
                    <a href="https://github.com/Anant/cassandra.vision/tree/master/cassandra-analyzer/offline-log-collector/TableAnalyzer">
                      Tableanalyzer / cassandra.vision
                    </a>
                  </Typography>
                  <ul>
                    <li>
                      A python based cfstat data anlyzer with a future in being
                      able to visualize other Cassandra / Distributed platform
                      stats.
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>
                    <a href="https://github.com/JeremyGrosser/tablesnap">
                      Tablesnap
                    </a>
                  </Typography>
                  <ul>
                    <li>
                      Tablesnap is a script that uses inotify to monitor a
                      directory for IN_MOVED_TO events and reacts to them by
                      spawning a new thread to upload that file to Amazon S3,
                      along with a JSON-formatted list of what other files were
                      in the directory at the time of the copy.
                    </li>
                    <li>
                      To install and configure tablesnap follow{" "}
                      <b>Install tablesnap for AWS S3 backups</b> section in{" "}
                      <b>dseauto/ansible/cassandra/README.md</b> document.
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>
                    <a href="https://github.com/prometheus/node_exporter">
                      node_exporter
                    </a>
                  </Typography>
                  <ul>
                    <li>
                      Prometheus exporter for hardware and OS metrics exposed by
                      *NIX kernels, written in Go with pluggable metric
                      collectors.
                    </li>
                    <li>
                      To install and configure node_exporter follow{" "}
                      <b>
                        Install node_exporter for exporting node metrics to
                        prometheus server
                      </b>{" "}
                      section in <b>dseauto/ansible/cassandra/README.md</b>{" "}
                      document.
                    </li>
                  </ul>
                </li>
                <li>
                  <Typography>
                    <a href="https://github.com/Anant/cassandra.tools/tree/master/cassandra-reaper">
                      Cassandra Reaper
                    </a>
                  </Typography>
                  <ul>
                    <li>
                      Reaper is an open source tool that aims to schedule and
                      orchestrate repairs of Apache Cassandra clusters.
                    </li>
                    <li>It improves the existing nodetool repair process by</li>
                    <ul>
                      <li>
                        Splitting repair jobs into smaller tunable segments.
                      </li>
                      <li>
                        Handling back-pressure through monitoring running
                        repairs and pending compactions.
                      </li>
                      <li>
                        Adding ability to pause or cancel repairs and track
                        progress precisely.
                      </li>
                    </ul>
                  </ul>
                </li>
              </ul>
              <Typography variant="h3">Credits</Typography>
              <Divider sx={{ margin: "20px 0" }} />
              <ul>
                <li>
                  Rahul Singh - Concept, Curator, Creator of{" "}
                  <a href="https://github.com/Anant/cassandra.vision/tree/master/cassandra-analyzer/offline-log-collector/TableAnalyzer">
                    tableanalyzer
                  </a>
                </li>
                <li>Sean Bogaard - Concept, Advisor, Curator</li>
                <li>Ion Olaru - Testing, Documentation of 3rd Party Tools</li>
              </ul>
              <Typography>
                Eventually we want compatability for the following items:
              </Typography>
              <table>
                <thead>
                  <tr>
                    <th>Platform</th>
                    <th>Receive</th>
                    <th>Transform</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>DSE 4.8.x</td>
                    <td>Diagnostic Tarball</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 4.8.x/C* 2.1.x</td>
                    <td>Nodetool</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 4.8.x/C* 2.1.x</td>
                    <td>SSH</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 5.1.x</td>
                    <td>Diagnostic Tarball</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 5.1.x/C* 3.1.x</td>
                    <td>Nodetool</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 5.1.x/C* 3.1.x</td>
                    <td>SSH</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 6.7.x</td>
                    <td>Diagnostic Tarball</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 6.7.x/C* 4.0.x</td>
                    <td>Nodetool</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>DSE 6.7.x/C* 4.0.x</td>
                    <td>SSH</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>Scylla?</td>
                    <td>Tarball</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>Elassandra?</td>
                    <td>Tarball</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>YugaByte?</td>
                    <td>Tarball</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>CosmosDB?</td>
                    <td>Tarball</td>
                    <td>Y</td>
                  </tr>
                  <tr>
                    <td>AWS MCS?</td>
                    <td>Tarball</td>
                    <td>Y</td>
                  </tr>
                </tbody>
              </table>
              <Typography variant="h3">Resources</Typography>
              <Divider sx={{ margin: "20px 0" }} />
              <Typography variant="h4">Backup</Typography>
              <ul>
                <li>
                  <a href="https://thelastpickle.com/blog/2018/04/03/cassandra-backup-and-restore-aws-ebs.html">
                    https://cassandra-backup-and-restore-aws-ebs
                  </a>
                </li>
                <li>
                  <a href="https://8kmiles.com/blog/cassandra-backup-and-restore-methods/">
                    https://8kmiles.com/blog/cassandra-backup-and-restore-methods/
                  </a>
                </li>
                <li>
                  <a href="https://github.com/JeremyGrosser/tablesnap">
                    https://github.com/JeremyGrosser/tablesnap
                  </a>
                </li>
                <li>
                  <a href="https://devops.com/things-know-planning-cassandra-backup">
                    https://devops.com/things-know-planning-cassandra-backup
                  </a>
                </li>
                <li>
                  <a href="http://techblog.constantcontact.com/devops/cassandra-and-backups/">
                    http://cassandra-and-backups
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/pulse/snap-cassandra-s3-tablesnap-vijaya-kumar-hosamani/">
                    https://www.linkedin.com/pulse/snap-cassandra-s3-tablesnap-vijaya-kumar-hosamani/
                  </a>
                </li>
                <li>
                  <a href="http://datos.io/2017/02/02/choose-right-backup-solution-cassandra/">
                    hthttp://datos.io/2017/02/02/choose-right-backup-solution-cassandra/
                  </a>
                </li>
              </ul>
              <Typography variant="h4">Cluster Management</Typography>
              <ul>
                <li>
                  <a href="https://github.com/riptano/ccm">
                    https://github.com/riptano/ccm
                  </a>
                </li>
              </ul>

              <Typography variant="h4">
                Snapshot strategy (future changes)
              </Typography>

              <ol>
                <li>
                  Take / keep a snapshot every 30 min for the latest 3 hours;
                </li>
                <li>
                  Keep a snapshot every 6 hours for the last day, delete other
                  snapshots;
                </li>
                <li>
                  Keep a snapshot every day for the last month, delete other
                  snapshots;
                </li>
              </ol>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default CassandraToolKit

export const pageQuery = graphql`
  query OpenSource2 {
    openSourceList: allCassandraLinks(
      filter: { domain_name: { regex: "/github.com/" }, content: { ne: null } }
      sort: { fields: wallabag_created_at, order: DESC }
      limit: 12
    ) {
      nodes {
        alternative_id
        title
        origin_url
        url
        wallabag_created_at

        published_at

        language
        reading_time
        domain_name
        preview_picture
        tags
      }
    }
  }
`
